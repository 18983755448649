<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>项目实施</el-breadcrumb-item>
                <el-breadcrumb-item>{{project.category==0?"项目交付图片":"业主验收单"}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-plus" @click="update">编辑</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-image style="width: 150px; height: 150px ; margin:20px ;" v-for="item in m.imageUrlFile" :key="item.name" :src="item.url" :preview-src-list="srcList">
            </el-image>

            <!-- 分页 -->
        </div>

        <!-- 增改组件 -->
        <add-or-update ref="add-or-update"></add-or-update>
    </div>
</template>
<script>
import addOrUpdate from "./add.vue";
export default {
    components: {
        addOrUpdate
        //inputEnum,
    },
    data() {
        return {
            m: {},
            project: { category: 0 }
        };
    },
    methods: {
        // 数据刷新
        f5: function() {
            this.sa
                .get(
                    "/deliver/list/" + this.project.id + "/" + this.project.category
                )
                .then(res => {
                    if (res.data.length > 0) {
                        let data = res.data ? res.data[0] : [];
                        data.imageUrlFile = JSON.parse(data.imageUrl || "[]");
                        this.srcList = data.imageUrlFile.map(item => {
                            return item.url;
                        });
                        data.filesFile = JSON.parse(data.files || "[]");
                        this.m = data;
                    } else {
                        this.m = {
                            projectId: this.project.id,
                            imageUrl: "",
                            files: "",
                            filesFile: [],
                            imageUrlFile: [],
                            category: this.project.category
                        };
                    }
                });
        },
        //更新
        update() {
            if (!this.m) {
                this.m.projectId = this.project;
            }

            console.log("伤心太平洋", this.m);
            this.$refs["add-or-update"].open(this.m);
        }
    },
    created: function() {
        this.project = this.sa_admin.params;
        this.f5();
    }
};
</script>

<style>
</style>
